import React from "react";

const VideoFilmMaking = () => {
  return (
    <section id="video_film_making">
      <div className="pt-2">
        <img
          src="assets/images/BrandBiz11.png"
          className="img-fluid"
          alt="VideoFilmMaking"
        ></img>
      </div>
    </section>
  );
};

export default VideoFilmMaking;
