import React from "react";

const LegalSolutions = () => {
  return (
    <section id="legal_solution">
      <div className="pt-2">
        <img
          src="assets/images/BrandBiz17.png"
          className="img-fluid"
          alt="LLegalSolutionsogo"
        ></img>
      </div>
    </section>
  );
};

export default LegalSolutions;
