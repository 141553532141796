import React from "react";

const BusinessConsulting = () => {
  return (
    <section id="business_consulting">
      <div className="pt-2">
        <img
          src="assets/images/BrandBiz19.png"
          className="img-fluid"
          alt="BusinessConsulting"
        ></img>
      </div>
    </section>
  );
};

export default BusinessConsulting;
